<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - アポ率・受注率</h1>
    </header>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-14">
                <label class="form-label">事業部</label>
                <form-select-search-multiple
                    v-model="condition.department_ids"
                    :options="options_department"
                >
                </form-select-search-multiple>
            </div>
            <div class="col-14">
                <label class="form-label">チャネル</label>
                <form-select-search-multiple
                    v-model="condition.channel_ids"
                    :options="options_channel"
                ></form-select-search-multiple>
            </div>
            <div class="col-6">
            <button @click="search" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else>
            <table class="table table-bordered" style="max-width: 1000px">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-3">月（集計期間）</th>
                        <th class="text-center col-1">反響数</th>
                        <th class="text-center col-1">アポ率</th>
                        <th class="text-center col-1">アポ数</th>
                        <th class="text-center col-1">アポ後受注率</th>
                        <th class="text-center col-1">受注数</th>
                        <th class="text-center col-1">受注率</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="rate, index in rate_collections" :key="index">
                        <tr>
                            <td class="align-middle text-center">{{ rate.month }}月（{{ rate.date_from }} ~ {{ rate.date_to }}）</td>
                            <td class="align-middle text-end table-light">{{ $helper.number(rate.contact_count) }}</td>
                            <td class="align-middle text-end">{{ rate.appointment_rate }}%</td>
                            <td class="align-middle text-end table-light">{{ $helper.number(rate.appointment_count) }}</td>
                            <td class="align-middle text-end">{{ rate.order_rate_after_appointment }}%</td>
                            <td class="align-middle text-end table-light">{{ $helper.number(rate.order_count) }}</td>
                            <td class="align-middle text-end">{{ rate.order_rate }}%</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </section>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Department from '@/models/entities/department';
import Channel from '@/models/entities/channel';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';

export default {
    name: 'ReportRate',
    components: {
        InlineLoader,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            options_channel: [],

            // 検索条件
            condition: {
                department_ids: this.$helper.isBlank(this.$store.state.auth.department_id) ? [] : [this.$store.state.auth.department_id],
                channel_ids: [],
            },

            rate_collections: [],
        }
    },
    mounted() {
        this.fetchDepartments(),
        this.fetchChannels(),
        this.search();
    },
    methods: {
        // 事業部マスタ取得
        fetchDepartments() {
            this.loading_count++;

            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    const department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // チャネルマスタ取得
        fetchChannels() {
            this.loading_count++;

            this.$http.get('/master/channel')
            .then(response => {
                for (let row of response.data) {
                    const channel = new Channel(row);
                    this.options_channel.push({value: channel.channel_id, label: channel.channel_name});
                }
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 検索
        search() {
            this.loading_count++;
            // 表示データ初期化
            this.rate_collections.splice(0);

            this.$http.get('/report/rate', {
                params: this.condition,
            })
            .then(response => {
                for (let row of response.data) {
                    this.rate_collections.push(row);
                }
            })
            .finally(() => {
                this.loading_count--;
            })
        },
    }
}
</script>

<style scoped>

</style>
